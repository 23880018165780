import React, { useState } from 'react';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Image from 'react-bootstrap/Image';

import { Works } from "./Works";

const App = () => (
  <Container>
    <Navbar className="navbar-dark" expand={false}>
      <Container fluid>
        <Navbar.Brand href="#">research + design</Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" />
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">freesoul.dev</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="https://freesoul.dev">home</Nav.Link>
              <Nav.Link href="https://read.cv/freesoul">vitae</Nav.Link>
              <Nav.Link href="mailto:mail@freesoul.dev">mail@freesoul.dev</Nav.Link>
              {/* <NavDropdown title="contact" id="offcanvasNavbarDropdown">
                <NavDropdown.Item href="mailto:mail@freesoul.dev">mail@freesoul.dev</NavDropdown.Item>
              </NavDropdown> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    <Works />
  </Container>
);

export default App;
